<template>
  <div class=" ">
    <titleBar
      title="Bookmarks"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="true"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />
    <div class="section">
      <div class="container">
        <br />

        <div class=" " v-if="items.length">
          <h3 class="title is-3">
            Onlybot pages, AI creators & AI tools
            <!--  
            <DotDrop
              :items="[
                { t: 'Popular', icon: 'far fa-fire', to: `soon` },
                // { t: 'Refresh', icon: 'far fa-sync', click: alert },
                { t: 'Following', icon: 'far fa-user-plus', to: `soon` },
              ]"
            />
            -->
          </h3>

          bullet list in 3 cols
          <div class="content listing">
            <div class="columns is-multiline is-mobile">
              <div class="column is-6-mobile is-4-tablet is-4-desktop is-3-fullhd" v-for="b in items" :key="b.handle">
                <router-link :to="'/' + b.handle">
                  {{ b.handle }}
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <div class=" " v-if="!items.length && !loading"></div>
      </div>
    </div>
  </div>
</template>

<script>
//import postList
import postList from "@/components/PostList.vue";
import DotDrop from "@/components/e/DotDrop.vue";

import NewPostTeaser from "@/components/studio/NewPostTeaser.vue";

export default {
  data() {
    return {
      items: [],
      activeTab: 0, // Index of the active tab
      loading: true,
      tabs: [
        { route: "/following", label: "Following" },
        { route: "/latest", label: "Latest" },
        { route: "/trending", label: "Trending" },
        // Add more tabs here
      ],
    };
  },
  components: {
    // postList,
    //  DotDrop,
    // NewPostTeaser,
  },

  methods: {
    async load() {
      try {
        //   const response = await fetch("/api/bots");
        const data = await window.API.getBots();
        this.loading = false;
        this.items = data;
      } catch (error) {
        console.error(error);
      }
    },
    bookmarkRemoved(post) {
      //   alert("Bookmark removed" + post);
      this.items = this.items.filter((item) => item.id !== post);
    },
    navigate(newIndex) {
      // this.$router.push(this.tabs[newIndex].route);
    },
    truncate(text2, length) {
      if (!text2) return;
      var text = String(text2);
      if (text.length > length) {
        return String(text).substring(0, length) + "...";
      }
      return text;
    },
    viewBot(bot) {
      // Code to view bot details
    },
    manageBot(bot) {
      // Code to manage bot settings
    },
  },
  mounted() {
    this.load();
    const tabIndex = this.tabs.findIndex((tab) => tab.route === this.$route.path);
    this.activeTab = tabIndex === -1 ? 0 : tabIndex;
  },
  watch: {
    "$route.path"() {
      const tabIndex = this.tabs.findIndex((tab) => tab.route === this.$route.path);
      this.activeTab = tabIndex === -1 ? 0 : tabIndex;
    },
  },
};
</script>

<style>
.botTable .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.botTable td {
  vertical-align: middle;
}
</style>
